import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IOptions, IProductDetailInfos } from "interface/DetailInterface"
import React, { useEffect, useRef, useState } from "react"
import { faChevronDown} from '@fortawesome/pro-regular-svg-icons'
import {faXmark } from "@fortawesome/pro-duotone-svg-icons";
import { priceComma } from "util/priceComma"
import { IReviewListResponse } from "interface/ReviewInterface";
import { isIOS } from "react-device-detect";
import { AnimatePresence, useInView } from "framer-motion";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Portal from "@components/Portal";
import { faDownLeftAndUpRightToCenter } from "@fortawesome/sharp-solid-svg-icons";
import FloatingNotice from "@components/snippets/FloatingNotice";
import CustomPopup from "@components/snippets/CustomPopup";

{/** 상품 디테일 정보 */}
const ProductDetailInfos = ({productDetail, optionInfo } : {productDetail ?: IProductDetailInfos | null, optionInfo ?: IOptions, reviews ?: IReviewListResponse | null} ) =>{

    const [choiceNo, setChoiceNo] = useState<string>('');
    const [optionName, setOptionName] = useState<string>('');
    const [optionDetails, setOptionDetails] = useState<string>();
    const [loadMore, setLoadMore] = useState<boolean>(false)

    // 상세 이미지 줌인 여부
    const [isZoomInDetailImg, setIsZoomInDetailImg] = useState<boolean>(false);
    // 상세페이지 줌인 안내 팝업 -> 팝업 디자인을 어떻게 해야될지 모르겠어서 주석처리함. 필요없게되면 지워도 상관없음
    // const [isRenderZoomNotice, setIsRenderZoomNotice] = useState<boolean>(false);

    const detailImgRef = useRef<HTMLDivElement>(null);
    const detailInfoRef = useRef<HTMLDivElement>(null);
    // 상세 이미지
    const productDetailRef = useRef<HTMLDivElement>(null);

    const isProductDetailInView = useInView(productDetailRef)


    {/** 옵션 별 디테일 이미지 정보 가져오기  */}
    const openOptionDetail = async(optionNo:number, choiceNo:string, optionName:string)=>{
        setChoiceNo(choiceNo)
        setOptionName(optionName)
        const currentOption = (optionInfo as IOptions)?.flatOptions?.filter(item=>item.optionNo === optionNo)[0]
        if(currentOption?.images){
            const currentImage = currentOption.images[1].url
            setOptionDetails(currentImage)
        }
    }

    {/** 옵션 디테일 이미지 창닫기 */}
    const closeOptionDetail = () =>{
        setOptionDetails('');
        (detailInfoRef.current as HTMLDivElement).scrollIntoView({block : 'start', behavior: "smooth"})
    }

    useEffect(()=>{
        if(optionDetails){
            (detailImgRef.current as HTMLDivElement).scrollIntoView({block : 'start', behavior: "smooth"})
        }
    },[optionDetails])

    function transformHtml(inputHtml: string): string {
        const hrefRegex = /href="([^"]+)"/g;
        
        let match;
        let transformedHtml = inputHtml;
    
        while (match = hrefRegex.exec(inputHtml)) {
            const hrefValue = match[1];
            const newAnchorTag = `<a onClick="window.webkit.messageHandlers.openWebview.postMessage('${hrefValue}')"`;
            transformedHtml = transformedHtml.replace(match[0], newAnchorTag);
        }
    
        return transformedHtml;
    }


    // 상세페이지 줌인 안내 팝업 -> 팝업 디자인을 어떻게 해야될지 모르겠어서 주석처리함. 필요없게되면 지워도 상관없음
    // useEffect(() => {
    //     if (!loadMore) return

    //     // 상세 이미지 줌인 기능 업데이트 안내 팝업: 최초 2회만 노출
    //     const zoomNoticeCount = localStorage.getItem('zoomNoticeCount');
    //     const hasBeenNoticed = parseInt(zoomNoticeCount || '0') >= 2;
    //     if (!hasBeenNoticed) {
    //         setIsRenderZoomNotice(true);
    //     }

    //     console.log(zoomNoticeCount, hasBeenNoticed);
    // }, [loadMore])

    return(
        <>
        
        <TransformWrapper
            // wheel을 사용해서 줌인아웃을 할 수 없도록 설정
            wheel={{ disabled: true }}
            // 상세이미지가 확대되어 있을때만 패닝이 가능하도록 설정 (상세 이미지 스크롤이 안되는 상황을 방지)
            panning={{ disabled: !isZoomInDetailImg }}
            onZoom={(tfRef) => setIsZoomInDetailImg(tfRef.state.scale > 1)}
        >
            {({ resetTransform }) => (
                <TransformComponent>
                    {/* 상세 이미지 */}
                    <div ref={detailImgRef} className={optionDetails ? "absolute top-0 w-full min-h-screen h-[100%] z-50 left-0 bg-white" : "hidden"}>
                        <div className="w-full h-[52px] bg-white relative border-b-[1px]">
                            <span className="absolute top-[50%] translate-y-[-50%] left-4 font-semibold">[ {choiceNo} ]</span>
                            <span className="absolute top-[50%] translate-y-[-50%] left-[78px]">{optionName}</span>
                            <FontAwesomeIcon onClick={closeOptionDetail} icon={faXmark} className="absolute top-[50%] translate-y-[-50%] right-5 w-4 h-5"/>
                        </div>

                        <div className="relative h-[120px] w-full bg-white">
                        <div className="absolute top-5 left-[50%] translate-x-[-50%] text-[14px] bg-[#9C5800] text-[white] font-bold rounded-[3px] px-[6px] py-[3px]">{choiceNo}</div>
                            <span className="absolute top-[60px] left-1/2 translate-x-[-50%] text-[18px] font-semibold">{optionName}</span>
                        </div>
                        <img src={optionDetails}/>
                    </div>
                    <div ref={productDetailRef} className={`relative forced-column w-screen ${loadMore ? '' : 'max-h-[1200px] overflow-hidden'}`}>
                        {isProductDetailInView && isZoomInDetailImg && (
                            <Portal>
                                <button
                                    className="fixed z-[1000000] top-[58px] left-[10px] w-9 h-9 bg-[#00000096] rounded-full flex justify-center items-center"
                                    onClick={() => {
                                        resetTransform()
                                        setIsZoomInDetailImg(false)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className="w-4.5 h-4.5 text-white" />
                                </button>
                            </Portal>
                        )}
                        {productDetail 
                        ? (<div> {/** --------------------------- 상단디테일 --------------------------- */}
                            {productDetail?.baseInfo.contentHeader !== '' && (
                                <div dangerouslySetInnerHTML={ {__html: productDetail?.baseInfo.contentHeader}}/>
                            )}

                            {/** --------------------------- 어드민에서 상세이미지 직접 등록했을 때의 화면 --------------------------- */}
                            {!!productDetail?.baseInfo.content && optionInfo
                            && (<div>
                                
                                {isIOS && productDetail ? <div dangerouslySetInnerHTML={ {__html: transformHtml(productDetail?.baseInfo.content) }}/> : <div dangerouslySetInnerHTML={ {__html: productDetail?.baseInfo.content}}/> }
                                

                                {/** --------------------------- 하단 디테일 --------------------------- */}
                                {!!productDetail.baseInfo.contentFooter && (
                                    <div dangerouslySetInnerHTML={ {__html: productDetail.baseInfo.contentFooter}}/>
                                )}

                                {!loadMore && (<div className="w-full p-4 bg-white h-[80px] absolute bottom-0">
                                    <div className="h-[52px] w-full border-[1px] border-[#2C2C2C] text-[#2C2C2C] text-center pt-[16px] text-[14px]" onClick={()=>{setLoadMore(true)}}>
                                        <span>상품설명 더 보기 </span>
                                        <FontAwesomeIcon className="ml-2 w-3 h-4" icon={faChevronDown} />
                                    </div>
                                </div> )}
                            </div>)}
                        </div>): (<></>)}
                    </div>
                </TransformComponent>
            )}
            
        </TransformWrapper>

        {/* // 상세페이지 줌인 안내 팝업 -> 팝업 디자인을 어떻게 해야될지 모르겠어서 주석처리함. 필요없게되면 지워도 상관없음 */}
        {/* <AnimatePresence>
            {isRenderZoomNotice && (
                <Portal>
                    <div className="fixed z-[100000] top-1/3 left-1/2 -translate-x-1/2 bg-white rounded-lg w-[80%] min-w-[300px] flex flex-col justify-between items-center shadow-[0_0_30px_8px_#00000020]">
                        <div className="text-center p-4 flex-1 flex flex-col justify-center items-center">
                            <div className="text-[1.1em] font-semibold">상세 이미지를 확대해보세요!</div>
                            <div className="mt-2 whitespace-pre-line">{`두 손가락으로 확대하여\n상세 이미지를 확대할 수 있습니다.`}</div>
                        </div>
                        <button
                            onClick={() => {
                                setIsRenderZoomNotice(false);
                                const zoomNoticeCount = localStorage.getItem('zoomNoticeCount') || '0';
                                const newZoomNoticeCount = parseInt(zoomNoticeCount) + 1;
                                localStorage.setItem('zoomNoticeCount', newZoomNoticeCount.toString());
                            }}
                            className="text-[1.02em] font-bold p-4 w-full"
                        >
                            확인
                        </button>
                    </div>
                </Portal>
            )}
        </AnimatePresence> */}
        </>
    )
}

export default ProductDetailInfos