import React, { ReactNode, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import usePreventScroll from "util/usePreventScroll"

interface PortalProps {
    children: ReactNode
}

const Portal = ({ children }: PortalProps) => {
    const [mounted, setMounted] = useState<boolean>(false)
    // usePreventScroll(mounted)

    useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [])

    if (typeof window === "undefined") return <></>

    return mounted ? (
        createPortal(
            children,
            document.getElementById("portal-root") as HTMLElement
        )
    ) : (
        <></>
    )
}

export default Portal
