import { cdnDomain } from "constant/cdnDomain"

export function getCDN340(filename:string):string {
  return cdnDomain + filename + "?resize=340x340";
}

export function getCDN920(filename:string):string {
  return cdnDomain + filename + "?resize=920";
}

export function getCDN340Width(filename:string):string {
  return cdnDomain + filename + "?resize=340";
}

export function getCDN(filename:string):string {
  return cdnDomain + filename
}

export function getCDN320Thumb(filename:string):string {
  const splitArray = filename.split('/')
  splitArray.splice(-1, 0, '320')
  return cdnDomain + splitArray.join('/');
}